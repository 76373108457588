import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/course-feedback";

export async function createFeedback(feedback: Learnlink.CourseFeedback.Create): Promise<void> {
  await learnlinkAPI.axios.post<void>(
    `${ENDPOINT}`, feedback,
  );
}
