


























































































































































































































import AgePicker from "@/components/onboarding/AgePicker.vue";
import AvailableTeamSlots from "@/components/onboarding/AvailableTeamSlots.vue";
import DayPickerForMultiWeekCourses from "@/components/onboarding/DayPickerForMultiWeekCourses.vue";
import WeekPickerForSingleWeekCourses from "@/components/onboarding/WeekPickerForSingleWeekCourses.vue";
import { computed, ComputedRef, defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { getCourse } from "@/api/courseService";
import { getTeamSessionStartTimesForOnboardingByCourse } from "@/api/teamSessionService";
import { useRouter } from "@/router";
import analytics from "@/services/analytics";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import Learnlink from "@learnlink/interfaces";
import { getTeamSettings } from "@/api/platformSettingsService";
import axios from "axios";
import { RoutesConfig } from "@/router/Routes";
import { getTeamsFromCourseId } from "@/api/teamService";
import rules from "@/helpers/formRules";
import { createFeedback } from "@/api/courseFeedbackService";
import { useNotifier } from "@/providers/notifier";

type Weekday = "mandag" | "tirsdag" | "onsdag" | "torsdag" | "søndag";

export default defineComponent({
  name: "AvailableCoursePickerFullPage",
  components: { AvailableTeamSlots, WeekPickerForSingleWeekCourses, DayPickerForMultiWeekCourses, AgePicker },
  setup() {
    const vm = reactive({
      birthYear: 2010,
      course: {} as Learnlink.Course.default,
      loading: true,
      teamCourseSessions: [] as Learnlink.App.Onboarding.TeamCourseSession[],
      timeUnit: "" as Weekday | number,
      teams: [] as Learnlink.Team.Team[],
      platformTeamSettings: { locations: [] } as Learnlink.PlatformSettings.Teams,
      error: null as {
        displayMessage: string,
        error: Error,
      } | null,
    });

    const { courseID } = useRouter().currentRoute.value.params;
    const { navigate } = useRouter();
    const dialogOpen = ref(false);

    onMounted(async () => {
      try {
        [vm.course, vm.teamCourseSessions, vm.platformTeamSettings] = await Promise.all([
          getCourse(courseID),
          getTeamSessionStartTimesForOnboardingByCourse(courseID),
          getTeamSettings(),
        ]);

        if (vm.course.location === "calendar") {
          vm.teams = await getTeamsFromCourseId(courseID);
        }
        else {
          const weeks: Array<{
            week: number,
            sessions: Learnlink.App.Onboarding.TeamCourseSession[]
          }> = [];
          for (const session of vm.teamCourseSessions) {
            const week: number = Moment.unix(session.session.startTime).week();
            const existing = weeks.find((value) => value.week === week);
            if (!existing) {
              weeks.push({
                week: week,
                sessions: [session]
              });
            }
            else {
              existing.sessions.push(session);
            }
          }
          for (const week of weeks) {
            const added: Array<string> = [];
            week.sessions = week.sessions.filter((value) => {
              if (!added.includes(value.team.location)) {
                added.push(value.team.location);
                return true;
              }
              return false;
            });
          }
          let unqiueWeek = weeks[0];
          if (unqiueWeek) {
            for (const week of weeks) {
              if (week.sessions.length > unqiueWeek.sessions.length) {
                unqiueWeek = week;
              }
            }
          }

          vm.birthYear = vm.teamCourseSessions[0]?.team.birthYears[0] || 2010;
          vm.timeUnit = vm.course.weeks > 1
            ? (vm.teamCourseSessions[0] ? Moment.unix(vm.teamCourseSessions[0].session.startTime).format("dddd") as Weekday : "" as Weekday)
            : unqiueWeek.week as unknown as Weekday ?? Moment.unix(vm.teamCourseSessions[0].session.startTime).week();
        }

        analytics.track("onboardingStarted");
        window.Intercom && window.Intercom("shutdown");
      }
      catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 404) {
            vm.error = {
              displayMessage: "Kurset finnes ikke 😅",
              error: e,
            };
          }
          else {
            vm.error = {
              displayMessage: `Noe gikk galt 😭 Code: ${e.response?.status}`,
              error: e,
            };
          }
        }
        else if (e instanceof Error) {
          vm.error = {
            displayMessage: "Noe gikk galt 😭",
            error: e,
          };
        }
        handleError(e);
      }

      vm.loading = false;
    });

    const goNextStep = () => {
      if (!vm.teams.length) {
        vm.error = {
          displayMessage: "Noe gikk galt 😭",
          error: new Error("No teams"),
        };
        return;
      }
      analytics.track("onboardingCoursePicked", {
        courseID: vm.teams[0]?.ID,
      });
      navigate(
        RoutesConfig.ORDER_TEAM.path
          .replace(":teamID", `${vm.teams[0]?.ID}`),
      );
    };

    const unavailable: ComputedRef<boolean> = computed(() => vm.timeUnit ? teamCourseSessionsByBirthYearAndTimeUnit.value.length === 0 : false);

    const teamCourseSessionsByBirthYearAndTimeUnit = computed(() =>
      vm.teamCourseSessions
        .filter(ageFilter)
        .filter(filterByTimeUnit),
    );

    function filterByTimeUnit(teamCourseSession: Learnlink.App.Onboarding.TeamCourseSession): boolean {
      if (vm.timeUnit) {
        const currentWeek = Moment.unix(teamCourseSession.session.startTime).week();
        const currentWeekday = Moment.unix(teamCourseSession.session.startTime).format("dddd");
        return currentWeekday === vm.timeUnit || currentWeek === vm.timeUnit;
      }
      return true;
    }

    function ageFilter(teamCourseSession: Learnlink.App.Onboarding.TeamCourseSession) {
      return teamCourseSession.team.birthYears.includes(vm.birthYear);
    }

    function contactMe(): void {
      analytics.track("onboardingContactMeClicked");
      window.open("https://www.learnlink.no/kodekurs/onsker-du-a-kontaktes", "_self");
    }

    const notifier = useNotifier();
    const contactMeReact = reactive({
      name: "",
      phone: "",
      email: "",
      day: [] as string[],
      feedback: "",
      processing: false,
    });
    async function contactMeTime(): Promise<void> {
      if (contactMeReact.processing) return;
      contactMeReact.processing = true;

      try {
        const payload: Learnlink.CourseFeedback.Create = {
          name: contactMeReact.name,
          phone: contactMeReact.phone,
          email: contactMeReact.email,
          message: contactMeReact.feedback,
          courseID: courseID,
          days: contactMeReact.day,
        };

        await createFeedback(payload);

        notifier({
          message: "Melding sendt!",
          type: "success",
          title: "Takk for at du tok kontakt!"
        });
      }
      catch (e) {
        notifier({
          message: "Noe gikk galt 😭",
          type: "error",
          title: "Kunne ikke sende melding! ta kontakt med support"
        });
        handleError(e);
      }

      contactMeReact.processing = false;
    }

    return {
      contactMe,
      contactMeReact,
      contactMeTime,
      teamCourseSessionsByBirthYearAndTimeUnit,
      unavailable,
      goNextStep,
      vm,
      dialogOpen,
      rules
    };
  },
});
